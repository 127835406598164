import { build_html, embed } from "./jsx.js";
import "./common.css";
import { defer } from "./defer_html.jsx";
import { head } from "./head.jsx";
import averta from "./fonts/averta/stylesheet.css";
import { header } from "./header.jsx";
import { footer } from "./footer.jsx";
import { section } from "./blocks/1/index.jsx";
let content = (
    <html lang="ru">
        {head}
        <body class={averta["normal-normal"]}>
            {header}
            <main>
                {section}
                {defer(async () => {
                    let { section } = await import("./blocks/2/index.jsx");
                    return section;
                })}
                {defer(async () => {
                    let { section } = await import("./blocks/3/index.jsx");
                    return section;
                })}
                {defer(async () => {
                    let { section } = await import("./blocks/4/index.jsx");
                    return section;
                })}
                {defer(async () => {
                    let { section } = await import("./blocks/5/index.jsx");
                    return section;
                })}
                {defer(async () => {
                    let { section } = await import("./blocks/6/index.jsx");
                    return section;
                })}
                {defer(async () => {
                    let { section } = await import("./blocks/7/index.jsx");
                    return section;
                })}
                {defer(async () => {
                    let { section } = await import("./blocks/8/index.jsx");
                    return section;
                })}
                {defer(async () => {
                    let { section } = await import("./blocks/9/index.jsx");
                    return section;
                })}
                {defer(async () => {
                    let { section } = await import("./blocks/10/index.jsx");
                    return section;
                })}
                {defer(async () => {
                    let { section } = await import("./blocks/11/index.jsx");
                    return section;
                })}
                {footer}
            </main>
        </body>
    </html>
);
embed(content);
