import { build_html, embed } from "./jsx.js";
import css from "./footer.css";
import fonts from "./fonts.css";

import vk from "./res/vk.svg";

let footer = (
    <footer>
        <div class={css["media"]}>
            <a href="https://vk.com/newfairlady" target="_blank">
                <img src={vk} class={css["svg"]} />
            </a>
        </div>
        <div class={fonts["h6"]}>© Студия-солнце, 2019. Все права защищены</div>
    </footer>
);
export { footer };
