import { build_html, embed } from "./jsx.js";

let head = (
    <head>
        <meta charset="UTF-8"></meta>
        <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta http-equiv="X-UA-Compatible" content="ie=edge"></meta>
        <link rel="stylesheet" href="style.css"></link>
        <title>Студия здоровых волос в Кудрово</title>
    </head>
);

if (location.hash != "#prerender") {
    (function(m, e, t, r, i, k, a) {
        m[i] =
            m[i] ||
            function() {
                (m[i].a = m[i].a || []).push(arguments);
            };
        m[i].l = 1 * new Date();
        (k = e.createElement(t)),
            (a = e.getElementsByTagName(t)[0]),
            (k.async = 1),
            (k.src = r),
            a.parentNode.insertBefore(k, a);
    })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    ym(57398665, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
    });
}
export { head };
